import React, { useState } from "react";
import "./App.css";

function App() {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const verifyCertificate = async () => {
    try {
      setErrorMessage("");
      setIsLoading(true);
      const response = await fetch(
        "https://6fpfkf6lcb.execute-api.us-east-2.amazonaws.com/dev/verifyCertificate",
        {
          mode: "cors",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            certificateNumber: certificateNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCertificateDetails(data);
      } else {
        setCertificateDetails(null);
        setErrorMessage("This certificate is not valid.");
      }
    } catch (error) {
      console.error(error);
      setCertificateDetails(null);
      setErrorMessage("An error occurred while verifying the certificate.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenewNowClick = () => {
    window.location.href = "https://bworkzlive.com/subscription/";
  };

  return (
    <div className="App">
      <div className="container">
        <h1>Certificate Verification</h1>
        <input
          type="text"
          placeholder="Enter Certificate Number"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
        />
        <button onClick={verifyCertificate}>Verify</button>
        {isLoading && <p>Loading...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {certificateDetails && (
          <div className="certificate-details">
            {certificateDetails.valid ? (
              <div>
                <h3>
                  This Certificate is {certificateDetails.details.status}.
                </h3>
                <p>
                  Issued Organization:{" "}
                  {certificateDetails.details.issuedOrganization}
                </p>
                <p>Issued To: {certificateDetails.details.issuedTo}</p>
                <p>Issued Date: {certificateDetails.details.issuedDate}</p>
                <p>Valid Upto : {certificateDetails.details.validUpto}</p>
                {certificateDetails.details.status === "Expired" && (
                  <div className="renew-button">
                    <button onClick={handleRenewNowClick}>Renew Now</button>
                  </div>
                )}
              </div>
            ) : (
              <p>{certificateDetails.message}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
